import React from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";

import { fetchQueuedTriggers } from "@/shared/rules/actions";
import PhoneNumberDisplay from "@/common/PhoneNumberDisplay";
import moment from '@/common/momentConfig';
import ConfirmModal from "@/common/ConfirmModal";
import { AssetActivityConsortiumIndicator } from "@/icons/Assets";
import {getDateString, getLocalDateStringFromUTC} from "../../common/LocalTimeStamp";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getQueuedTriggers: (page) => dispatch(fetchQueuedTriggers(page)),
})

function ActivityQueuedRules(props) {
    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        props.getQueuedTriggers(page);
    }, [page]);

    const loadMore = () => {
        setPage(page + 1);
    }

    const getContactName = (t) => {
        if (t.type === "SURVEY" && t.surveyInstance && t.surveyInstance.contact) {
            return t.surveyInstance.contact.readableName;
        } else if (t.triggerContact) {
            return t.triggerContact.readableName;
        } else if (t.encounter.contact?.readableName) {
            return t.encounter.contact.readableName;
        } else {
            return "";
        }
    };

    const getContactPhone = (t) => {
        if (t.type === "SURVEY" && t.surveyInstance && t.surveyInstance.contact) {
            return t.surveyInstance.contact.phone;
        } else if (t.triggerContact) {
            return t.triggerContact.phone;
        } else if (t.encounter.contact?.phone) {
            return t.encounter.contact.phone;
        } else {
            return "";
        }
    };

    const sendQueued = (queuedTrigger) => {
        API.post('Core', '/api/v1/rule/trigger/send/' + queuedTrigger.id)
            .then(response => {
                props.getQueuedTriggers(page);
            },
                error => {
                    toast("Could not execute rule target: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const ignoreQueued = (queuedTrigger) => {
        API.post('Core', '/api/v1/rule/trigger/ignore/' + queuedTrigger.id)
            .then(response => {
                props.getQueuedTriggers(page);
            },
                error => {
                    toast("Could not ignore rule target: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    if (!props.rules.queuedTriggers || props.rules.queuedTriggers.length == 0) {
        return null;
    }

    return (
        <div className="activitySection sectionPendingReview">
            <div className="detailedEncountersList">
                <div className="encountersTitle">Pending Review</div>
                <div className="detailedEncountersCells">

                    {
                        (props.rules.queuedTriggersPageInfo && !props.rules.queuedTriggersPageInfo.last) ?
                            (
                                <div onClick={loadMore} className="encountersExpander">
                                    <div className="expanderButton">
                                        <div className="title">Show More</div>
                                        <div className="icon" />
                                    </div>
                                </div>
                            ) : null
                    }

                    <TransitionGroup className="encounter-list">
                        {
                            (props.rules.queuedTriggers) ? _.map(_.reverse(_.sortBy(props.rules.queuedTriggers, function (t) {
                                return t.createdAt
                            })), function (t) {
                                let encounter = t.encounter;
                                let unitNames = encounter.units ? _.map(encounter.units, function (u) {
                                    return u.unitId;
                                }) : [];
                                return (
                                    <CSSTransition
                                        key={t.id}
                                        timeout={5000}
                                        classNames="encounter"
                                    >
                                        <div className="detailedEncounterCell tableCell">
                                            <div className="encounterCellBody">
                                                {
                                                    encounter.testEncounter ? (
                                                        <div className="bodyColumn columnTestStatus">
                                                            <div className="testIndicator">
                                                                <div className="indicatorTitle">TEST</div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }

                                                {t.matchingRule.tenantId != props.user.aboutMe.currentTenant.id ? (
                                                    <div className="bodyColumn columnConsortium">
                                                        <div className="consortiumIndicator">
                                                            <AssetActivityConsortiumIndicator className="indicatorIcon" />
                                                            {/*<div className="indicatorTitle">{props.user.aboutMe.currentTenant.id}</div>*/}
                                                        </div>
                                                    </div>
                                                ) : null}

                                                <div className="bodyColumn columnEncounterType">
                                                    <div className="bodyTitle encounterName">{encounter.encounterType}</div>
                                                    <div className="bodySubtitle encounterAddress">{encounter.encounterLocation != null ? encounter.encounterLocation.summary : ""}</div>
                                                </div>

                                                <div className="bodyColumn columnEncounterContact">
                                                    <div className="bodyTitle encounterContactName">
                                                        {getContactName(t)}
                                                    </div>
                                                    <div className="bodySubtitle encounterContactPhone">
                                                        <PhoneNumberDisplay phoneNumber={getContactPhone(t)} />
                                                    </div>
                                                </div>

                                                <div className="bodyColumn columnEncounterTime">
                                                    <div className="bodyTitle encounterTimeReported">{
                                                       (encounter.source && encounter.source.utc)?
                                                        getLocalDateStringFromUTC(encounter.encounterDate, "MM/DD/YYYY, h:mm:ss a"): getDateString(encounter.encounterDate, "MM/DD/YYYY, h:mm:ss a")
                                                    }</div>
                                                    <div className="bodySubtitle encounterTimeResponded">{/*Responded in 2h 54m 37s*/}</div>
                                                </div>

                                                <div className="bodyColumn columnEncounterResponder">
                                                    <div className="bodyTitle encounterResponderType">{unitNames.join(", ")}</div>
                                                </div>
                                            </div>

                                            <div className="encounterCellDetail">
                                                <div className="encounterTrigger">
                                                    <div className="triggerItem triggerRule">
                                                        <div className="title">{t.type}: {t.matchingRule.name}</div>
                                                    </div>

                                                    {/*<div className="triggerItem arrow">*/}
                                                    {/*    <div className="icon"/>*/}
                                                    {/*</div>*/}

                                                    {
                                                        (t.surveyInstance) ? (
                                                            <div className="triggerItem triggerTarget">
                                                                <div className="title">{t.surveyInstance.surveyName}</div>
                                                            </div>
                                                        ) : null
                                                    }
                                                    <div className="targetStatus statusDelivery deliveryDelivered">
                                                        <div className="title">{t.status}</div>
                                                    </div>
                                                </div>

                                                <div className="encounterActions surveyActions">
                                                    <ConfirmModal
                                                        title={"Are you sure you want to ignore?"}
                                                        body={(
                                                            <div>
                                                                This system will mark this to be ignored. There is no way to undo this.
                                                            </div>
                                                        )}
                                                        confirmTitle={"Confirm"}
                                                        onConfirm={ignoreQueued.bind(this, t)}
                                                    >
                                                        <div className="encounterActions actionIgnore button small action">
                                                            <div className="title">Ignore</div>
                                                        </div>
                                                    </ConfirmModal>
                                                    <ConfirmModal
                                                        title={t.type == "TEXT" ? "Are you sure you want to send this message?" : "Are you sure you want to send this survey?"}
                                                        body={(
                                                            <div>
                                                                The system will text <PhoneNumberDisplay phoneNumber={getContactPhone(t)} /> {t.type === "TEXT" ? (
                                                                <b>{t.message}</b>
                                                            ) : (
                                                                <span>{t.surveyInstance?.surveyName ?? ""}</span>
                                                            )}
                                                            </div>
                                                        )}
                                                        confirmTitle={"Send Message"}
                                                        onConfirm={sendQueued.bind(this, t)}
                                                    >
                                                        <div className="encounterActions actionSend button small tintProminent">
                                                            <div className="title">Send</div>
                                                        </div>
                                                    </ConfirmModal>
                                                </div>
                                            </div>
                                        </div>
                                    </CSSTransition>
                                )
                            }) : null
                        }
                    </TransitionGroup>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityQueuedRules);
