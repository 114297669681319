import React from 'react';
import { withRouter } from "react-router";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import InputCell from "../../common/form/InputCell";
import { useTranslation } from 'react-i18next';
import useDocumentTitle from "../../common/UseDocumentTitle";

function RequestPublicSurvey(props) {
    const { t } = useTranslation();
    const { surveyId } = props.match.params;
    const [survey, setSurvey] = React.useState(null);
    const [surveyName, setSurveyName] = React.useState(null);
    const [tenant, setTenant] = React.useState(null);
    const [notFound, setNotFound] = React.useState(false);
    const [recaptchaLoaded, setRecaptchaLoaded] = React.useState(false);
    const emailRef = React.createRef();
    const phoneRef = React.createRef();
    useDocumentTitle(`PowerEngage - Take Survey ${tenant ?'- '+ tenant.name :''}`);

    const loadRecaptcha = (callback) => {
        let scriptId = "grecaptcha-script";
        const existingScript = document.getElementById(scriptId);
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=' + window.__RUNTIME_CONFIG__.RECAPTCHA_SITE_KEY;
            script.id = scriptId;
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) {
                    callback();
                }
            };
        }
        if (existingScript && callback) {
            callback();
        }
    };

    React.useEffect(() => {
        document.body.classList.remove('prefersDarkTheme');
        loadRecaptcha(() => {
            setRecaptchaLoaded(true);
        })
        API.get('Unauthorized', '/u/survey/definition/' + surveyId).then(response => {
            setSurvey(response.survey);
            setTenant(response.tenant);
            setSurveyName(response.surveyName);
        },
            error => {
                setNotFound(true);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = () => {
        const grecaptcha = window.grecaptcha;
        grecaptcha.ready(function () {
            grecaptcha.execute(window.__RUNTIME_CONFIG__.RECAPTCHA_SITE_KEY, { action: 'SURVEY_START' }).then(function (token) {
                let anonRequest = {
                    email: emailRef.current.value,
                    phone: phoneRef.current.value,
                    recaptchaToken: token
                }
                API.post('Unauthorized', '/u/survey/definition/' + surveyId, { body: anonRequest }).then(response => {
                    props.history.push("/take-survey/" + response.token);
                },
                    error => {
                        toast("Could not start survey: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                    })
            })
        })
    }

    if (notFound) {
        return (
            <div className="publicSurveyForm surveyNotFound">
                <div className="statusMessage">
                    <h1 className="messageTitle">Survey Not Found</h1>
                    <p className="messageBody">This survey is no longer available.</p>
                </div>
            </div>
        )
    }

    if (!survey || !tenant || !surveyName || !recaptchaLoaded) {
        return (
            <div className="publicSurveyForm surveyNotFound">
                <div className="statusMessage">
                    <h1 className="messageTitle">Loading...</h1>
                </div>
            </div>
        )
    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    }

    return (
        <div className="publicSurveyForm">
            <div className="surveyDetails">
                {
                    tenant.logoFilename ? (
                        <img style={{ maxWidth: '210px', maxHeight: '100px' }} alt="logo" src={"/logos/" + tenant.logoFilename} />
                    ) : (<h1 className="surveyAgencyName">{tenant.name}</h1>)
                }
                <div className="surveyTitle">{surveyName} - {survey.questions.length} {t('questions')}</div>
            </div>
            <div className="surveyHeader">
                <p className="headerText">{t('Your feedback is appreciated!')}</p>
                <div style={{ width: '90%' }} className="formContent tableView">
                    <div style={{ paddingTop: '10px' }} className="tableSection sectionContact">
                        <InputCell
                            placeholder={t('citizen@gmail.com')}
                            required
                            id="email"
                            label="Email"
                            inputRef={emailRef}
                        />
                    </div>
                    <p style={{ paddingTop: '10px', textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }} className="headerText">{t('OR')}</p>
                    <div style={{ paddingTop: '10px' }} className="tableSection sectionContact">
                        <InputCell
                            placeholder={"(123) 456-7890"}
                            required
                            id="phone"
                            label={t('Phone')}
                            inputRef={phoneRef}
                        />
                    </div>
                </div>
            </div>

            <div className="surveyFooter">
                <div onClick={onSubmit} onKeyDown={onKeyDownHandler} tabIndex="0" className="button large confirm submitSurvey">
                    <div className="buttonTitle" role="button">{t('Start Survey')}</div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(RequestPublicSurvey);