import React from "react";
import Modal from "./Modal";

export default function ConfirmModal(props) {
    var {title, text, body, onConfirm, onCancel, confirmTitle} = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        onConfirm();
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
        if (onCancel) {
            onCancel();
        }
    };

    const getConfirmBody = () => {
        return (
            <div>

                <div className={"alertBody"}>
                    <h1 className={"alertTitle"}>{title}</h1>
                    <div className={"alertMessage"}>
                        {
                            body ? body : (<div>{text}</div>)
                        }
                    </div>
                </div>

                <div className={"alertActions dualActions"}>
                    <div onClick={handleClose} className={"alertAction button action medium"}>
                        <div className={"buttonTitle"}>Cancel</div>
                    </div>

                    <div onClick={handleConfirm} className={"alertAction button confirm medium"}>
                        <div className={"buttonTitle"}>{confirmTitle}</div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <Modal
            button={(<div onClick={handleOpen}>{props.children}</div>)}
            content={getConfirmBody()}
            dialogClassName={"alertModal"}
            title={null}
            size="xs"
            handleClose={handleClose}
            handleOpen={handleOpen}
            open={open}
        />
    )
}