const eidConfig = {
    oidc: {
        authority: `${window.__RUNTIME_CONFIG__.EID_AUTHORITY_URL}`,
        clientId: `${window.__RUNTIME_CONFIG__.EID_CLIENT_ID}`,
        redirectUri: `${window.__RUNTIME_CONFIG__.API_HOST}/callback`,
        productCode: `${window.__RUNTIME_CONFIG__.EID_PRODUCT_CODE}`,
        postLogoutRedirectUri: `${window.__RUNTIME_CONFIG__.API_HOST}/logout/callback`,
        responseType: "code",
        scope: "openid profile email offline_access",
        automaticSilentRenew: true,
    }
};

export default eidConfig;