import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({startDate, endDate, onDateChange}) => {
    const CustomInput = React.forwardRef(({value, onClick}, ref) => (
        <input
            type="text"
            className="datepicker-custom-input"
            value={value}
            onClick={onClick}
            ref={ref}
            placeholder="Select a date range."
            readOnly
        />
    ));

    return (
        <div className="datePickerWrapper">
            <DatePicker
                selected={startDate}
                onChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable
                showIcon
                dateFormat="yyyy/MM/dd"
                maxDate={new Date()}
                customInput={<CustomInput/>}
            />
        </div>
    );
};

export default DateRangePicker;