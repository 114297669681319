import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import {IconButton, makeStyles} from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import _ from 'lodash';

import AddEditUser from "@/components/AddEditUser";
import Modal from "@/common/Modal";
import ConfirmModal from "@/common/ConfirmModal";
import PresenceIndicator from "@/common/PresenceIndicator";
import InputCell from "@/common/form/InputCell";
import { fetchProvidersList, fetchRoles, fetchSystemPrivileges } from "@/shared/user/actions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TableDisplay from "../../../common/TableDisplay";
import {
    isDepartmentFeatureDisabled,
    isPlatformPasswordManagementEnabled,
    isPlatformUserSyncEnabled
} from "../../../shared/access";
import Tab from "@material-ui/core/Tab";
import {getAuthorization} from "../../../setupAmplify";


const mapStateToProps = state => ({
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    getProviders: () => dispatch(fetchProvidersList()),
    getSystemPrivileges: () => dispatch(fetchSystemPrivileges()),
    getRoles: () => dispatch(fetchRoles())
})
const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'right'
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    completed: {
        color: theme.palette.success[600] + "!important",
    }
}));



function TeamIndex(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState({});
    const [passwordResetUser, setPasswordResetUser] = React.useState(null);
    const [tempPassword, setTempPassword] = React.useState(null);
    const passwordRef = React.createRef();

    React.useEffect(() => {
        props.getProviders();
        props.getSystemPrivileges();
        props.getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUserInvite = (user) => {
        setOpen(false);
        props.getProviders()
    };

    const handleEditOpen = (userId) => {
        editOpen[userId] = true;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditClose = (userId) => {
        editOpen[userId] = false;
        setEditOpen(Object.assign({}, editOpen));
    };

    const handleEditSuccess = (userId) => {
        editOpen[userId] = false;
        setEditOpen(Object.assign({}, editOpen));
        props.getProviders();
    }

    const deleteUser = (userId) => {
        API.del('Core', '/api/v1/users/provider/' + userId)
            .then(response => {
                props.getProviders();
                toast("Deleted user!", { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.SUCCESS });
            },
                error => {
                    toast("Could not delete user" + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const resetPassword = async (user) => {
        if (isPlatformUserSyncEnabled() && isPlatformPasswordManagementEnabled()) {
            if (!user.globalUserId || user.globalUserId === "" || user.globalUserId === "NULL") {
                toast("Could not reset password, the Global user id is missing", {
                    position: toast.POSITION.TOP_CENTER,
                    type: toast.TYPE.ERROR
                });
                return;
            }
            const host = window.__RUNTIME_CONFIG__.API_HOST;
            const resetPasswordHost=window.__RUNTIME_CONFIG__.EID_AUTHORITY_URL;
            const token = await getAuthorization();
            fetch(resetPasswordHost + 'accounts/' + user.globalUserId + '/reset-credentials/link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    returnurl: host + "/settings/team"
                })
            })
                .then(async response => {
                    if (!response.ok) {
                        const errorResponse = await response.json();
                        const errorMessage = errorResponse.errorMessages
                            ? errorResponse.errorMessages.join(', ')
                            : 'Unknown error occurred';
                        throw new Error(errorMessage); // Throw error for the catch block
                    }
                    return response.json(); // Parse JSON for successful response
                })
                .then(data => {
                    if (data.adminPasswordResetLink) {
                        window.open(data.adminPasswordResetLink, '_blank'); // Open link in a new tab
                    } else {
                        toast("Admin password reset link is missing in the response.", {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.ERROR
                        });
                    }
                })
                .catch(error => {
                    toast("Could not reset password: " + error.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                });
        } else {


            API.put('Core', '/api/v1/users/provider/' + user.id + '/temporary-password')
                .then(response => {
                        setPasswordResetUser(user);
                        setTempPassword(response.temporaryPassword);
                        toast("Successfully reset users password!", {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.SUCCESS
                        });
                    },
                    error => {
                        toast("Could not reset password" + error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER,
                            type: toast.TYPE.ERROR
                        });
                    }
                );
        }
    }

    const closePasswordReset = () => {
        const RESETTING_OWN_PASSWORD = props?.user?.aboutMe?.user?.id === passwordResetUser?.id;

        setPasswordResetUser(null);
        setTempPassword(null);
        if (RESETTING_OWN_PASSWORD) Auth.signOut();
    }

    const isOnline = (user) => {
        let found = _.find(props.user.onlinePresenceMembers, function (member) {
            return member.id === user.id;
        });
        return found === undefined? false: found;
    }

    const renderOnline = (row) => {
        let colorSelected = isOnline(row) && row.userStatus ? 'GREEN' : null;
        return (<PresenceIndicator color={colorSelected} />);
    }

    const sortOnline = (rowA,rowB) => {
        let sortA = isOnline(rowA);
        let sortB = isOnline(rowB);
        return sortA.toString().localeCompare(sortB.toString())
    }

    const renderStatus = row => {
        let userStatus = row.userStatus ? 'Active': 'Inactive';
        return (<span>
            {userStatus}
        </span>
        );
    }

    const renderRole = row => {
        let role = row.role.map(function (r) { return r.name; }).join(", ");
        return (<span>
            {role}
        </span>
        );
    }


    const columnsCommon = [
        { id: 'online', label: 'Online',title: 'Online', maxWidth: 45, render : renderOnline,
            customSort: sortOnline},
        { id: 'userStatus', label: 'Status', minWidth: 40, maxWidth: 55, title: 'Status', field: 'userStatus',
            render : renderStatus},
        { id: 'firstName', label: 'First Name', minWidth: 90, title: 'First Name', field: 'firstName',
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100},
            customSort: (a,b) => a?.firstName?.localeCompare(b?.firstName), },
        { id: 'lastName', label: 'Last Name', minWidth: 70, title: 'Last Name', field: 'lastName',
            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100},
            customSort: (a,b) => a?.lastName?.localeCompare(b?.lastName), },
        { id: 'email', label: 'Email', minWidth: 150, title: 'Email', field: 'email',
            customSort: (a,b) => a?.email?.localeCompare(b?.email), },
        { id: 'phone', label: 'Phone', minWidth: 90, title: 'Phone', field: 'phone' },
        { id: 'roleNames', label: 'Role', minWidth: 90, title: 'Role', field: 'roleNames', render : renderRole,
            customSort: (a, b) => a.roleNames.localeCompare(b.roleNames) },
    ];
    const flattenData = (data) => {
        return data.map(user => ({
            ...user,
            // onlineValue: user.onlineValue.map(r => r.name).join(', '), // flatten roles
            roleNames: user.role.map(r => r.name).join(', '), // flatten roles
            departmentName: user.department ? user.department.name : '', // flatten department
        }));
    };
    const dataWithFlattenedAttributes = flattenData(props.user.providers);


    const columnDepartment = [...columnsCommon,
        { id: 'departmentName', label: 'Department', minWidth: 90, title: 'Department', field: 'departmentName' }
    ];

    const columns = isDepartmentFeatureDisabled() ? columnsCommon : columnDepartment;

    const renderActions = row => {
        let flexContainer = {display:'flex'};
        return (
            <div style={flexContainer}>
                <Modal
                    button={(
                        <IconButton color="primary">
                            <EditIcon/>
                        </IconButton>
                    )}
                    content={(<AddEditUser
                        onCancel={handleEditClose.bind(this, row.id)}
                        onSuccess={handleEditSuccess.bind(this, row.id)}
                        roles={props.user.roles}
                        existingUser={row}
                    />)}
                    title="Edit User"
                    handleClose={handleEditClose.bind(this, row.id)}
                    handleOpen={handleEditOpen.bind(this, row.id)}
                    open={editOpen[row.id]}
                />
                <ConfirmModal
                    title={"Delete User"}
                    text={(
                        <span>Are you sure you want to delete <b>{row.firstName} {row.lastName}</b>?</span>)}
                    onConfirm={deleteUser.bind(this, row.id)}
                    confirmTitle={"Delete User"}
                >
                    <IconButton color="primary">
                        <DeleteIcon/>
                    </IconButton>
                </ConfirmModal>
                <ConfirmModal
                    title={"Reset Password"}
                    text={(
                        <span>Are you sure you want to reset password for <b>{row.firstName} {row.lastName}</b>?</span>)}
                    onConfirm={resetPassword.bind(this, row)}
                    confirmTitle={"Reset Password"}
                >
                    {
                        row.userStatus ?
                            (<Button variant="contained" color="primary" className={classes.button}>
                                Reset Password
                            </Button>):null
                    }

                </ConfirmModal>
                {
                    tempPassword && passwordResetUser?.id === row.id ? (
                        <Modal
                            content={(
                                <div>
                                    <div className="content">
                                        <div className="tableView">
                                            <div className="tableSection sectionName">
                                                <InputCell
                                                    placeholder={""}
                                                    defaultValue={passwordResetUser.email}
                                                    id="tempEmail"
                                                    label="Email"
                                                />
                                            </div>
                                            <div className="tableSection sectionName">
                                                <InputCell
                                                    placeholder={""}
                                                    defaultValue={tempPassword}
                                                    id="tempPass"
                                                    label="Password"
                                                    inputRef={passwordRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="actionBar">
                                        <div className="actions">
                                            <div
                                                onClick={closePasswordReset.bind(this, row)}
                                                className="button dismiss medium">
                                                <div className="title">Done</div>
                                            </div>
                                            {
                                                document.queryCommandSupported('copy') ? (
                                                    <div onClick={() => {
                                                        passwordRef.current.select();
                                                        document.execCommand('copy')
                                                        toast("Copied to clipboard!", {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            type: toast.TYPE.SUCCESS
                                                        });
                                                    }} className={`button confirm medium`}>
                                                        <div className="title">Copy Password
                                                            to Clipboard
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>

                                    <div className="separator"></div>
                                </div>
                            )}
                            title="Temporary Login Credentials"
                            size="small"
                            handleClose={closePasswordReset.bind(this, row)}
                            handleOpen={() => {
                            }}
                            open={tempPassword && passwordResetUser?.id === row.id}
                        />
                    ) : null
                }
            </div>);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Modal
                    button={(<Button variant="contained" color="primary" className={classes.button}>
                        Add User
                    </Button>)}
                    content={(<AddEditUser
                        systemPrivileges={props.user.systemPrivileges}
                        onCancel={handleClose}
                        onSuccess={handleUserInvite}
                        roles={props.user.roles}
                    />)}
                    title="Add User"
                    size="small"
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    open={open}
                />
            </Grid>
            <Grid item xs={12}>
                    <TableDisplay
                        columns={[...columns,
                            {
                                    title: 'Actions',
                                    field: 'actions',
                                    render: renderActions,
                                    sorting: false,
                                    headerStyle: {cursor: 'default'},
                                }]}
                        title='User'
                        options={{thirdSortClick: false}}
                        data={dataWithFlattenedAttributes} // use transformed data
                        pageSizeOptions={[10, 25, 50]}/>

            </Grid>
        </Grid>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamIndex);
