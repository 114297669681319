import React from 'react';
import Select from "react-select";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import _ from "lodash";
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

import { canQASurveys, isIgnoreAnswerEnabled } from "@/shared/access";
import { sentiments } from "@/utils/utils";

function SurveyResultsAnswerChoice({ question, answer, setAnswerIgnored }) {
    return (
        <div className="answerCell answerChoice">
            <div className="surveyQuestion">{question.prompt}</div>

            <div className="surveyAnswers">
                {
                    _.map(question.options, function (o) {
                        return (
                            <div key={o.id} className={`surveyAnswer ${(answer && answer.selectedOption && answer.selectedOption.id == o.id) ? 'selected' : ''}`}>
                                <div className="answerTitle">{o.option}</div>
                            </div>
                        )
                    })
                }
            </div>

            {/* {(isIgnoreAnswerEnabled() && answer) &&
        <div>
          <FormControlLabel
            disabled={!canQASurveys()}
            checked={answer.ignored}
            onChange={() => setAnswerIgnored(answer, !answer.ignored, question)}
            className="answerIgnore"
            control={<Checkbox color="primary" />}
            label="Exclude Rating"
          />
          {answer.ignored && (
            <div className="answerIgnoreComment">
              {"Reason: "}
              {answer.ignoredComment ? answer.ignoredComment : ""}
            </div>
          )}
        </div>
      } */}
        </div>
    );
}

function SurveyResultsAnswerScale({ question, answer, setAnswerIgnored }) {
    return (
        <div className="answerCell answerScale">
            <div className="surveyQuestion">{question.prompt}</div>

            <div className="surveyAnswers">
                {answer ?
                    _.map(_.range(question.low, question.high + 1), function (i) {
                        return (
                            <div key={i} className={`surveyAnswer ${answer.rangeSelection === i ? 'selected' : ''}`}>
                                <div className="answerTitle">{i}</div>
                            </div>
                        )
                    }) : ""
                }
            </div>

            <div className="answersLabels">
                <div className="label min">{question.lowDescription ? question.lowDescription : ''}</div>
                <div className="label max">{question.highDescription ? question.highDescription : ''}</div>
            </div>

            {(isIgnoreAnswerEnabled() && answer) &&
                <div>
                    <FormControlLabel
                        disabled={!canQASurveys()}
                        checked={answer.ignored}
                        onChange={() => setAnswerIgnored(answer, !answer.ignored, question)}
                        className="answerIgnore"
                        control={<Checkbox color="primary" />}
                        label="Exclude Rating"
                    />
                    {answer.ignored && (
                        <div className="answerIgnoreComment">
                            {"Reason: "}
                            {answer.ignoredComment ? answer.ignoredComment : ""}
                        </div>
                    )}
                </div>
            }
        </div>
    );
}

function SurveyResultsAnswerComment({ question, answer, setAnswerIgnored }) {

    const [sentiment, setSentiment] = React.useState(answer ? answer.sentiment : "");
    const [currentAnswer, setCurrentAnswer] = React.useState(answer);

    const saveSentiment = () => {
        currentAnswer.sentiment = sentiment;
        API.put('Core', '/api/v1/survey/answer/sentiment/' + currentAnswer.id, { body: currentAnswer })
            .then(response => {
                setCurrentAnswer(response);
            },
                error => {
                    toast("Could not update answer sentiment: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const setModififedSentiment = (obj) => {
        if (obj != null) {
            setSentiment(obj.value);
        }
    }

    return (
        <div className="answerCell answerComment">
            <div className="surveyQuestion">{question.prompt}</div>
            <div className="surveyAnswers">
                {currentAnswer ? (
                    <div className="surveyAnswer">
                        <div className="answerTitle">{answer.normalizedAnswer}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {currentAnswer ? (
                <div className="tableCell titledSelectCell sentimentSelect">
                    <label className="title" htmlFor="sentimentTypeSelect" id="sentiments1">
                        Sentiment
                    </label>
                    <Select
                        inputId="sentimentTypeSelect"
                        className="selectCell"
                        classNamePrefix="selectCell"
                        onChange={setModififedSentiment.bind(this)}
                        placeholder="Sentiment"
                        value={
                            sentiment !== "" ? { label: sentiment, value: sentiment } : ""
                        }
                        isDisabled={!canQASurveys()}
                        options={sentiments}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        aria-label="sentiment combobox: Use arrow key or press enter to activate the menu and select an option"
                        role="combobox"
                    />
                    {currentAnswer.sentiment !== sentiment && canQASurveys() ? (
                        <div
                            onClick={saveSentiment.bind(this)}
                            className="button medium tint"
                        >
                            <div className="title">Save</div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                ""
            )}

            {/*<div className="button action medium">*/}
            {/*  <div className="title">Add to Feedback Board</div>*/}
            {/*</div>*/}

            {/* {(isIgnoreAnswerEnabled() && answer) &&
        <div>
          <FormControlLabel
            disabled={!canQASurveys()}
            checked={answer.ignored}
            onChange={() => setAnswerIgnored(answer, !answer.ignored, question)}
            className="answerIgnore"
            control={<Checkbox color="primary" />}
            label="Exclude Rating"
          />
          {answer.ignored && (
            <div className="answerIgnoreComment">
              {"Reason: "}
              {answer.ignoredComment ? answer.ignoredComment : ""}
            </div>
          )}
        </div>
      } */}
        </div>
    );
}

export { SurveyResultsAnswerChoice, SurveyResultsAnswerScale, SurveyResultsAnswerComment };