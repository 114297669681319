import { connect } from "react-redux";
import React from "react";
import { withRouter } from 'react-router';
import _ from "lodash";
import moment from 'moment';
import {isPlatformUserSyncEnabled} from "../shared/access";


function mapStateToProps(state, props) {
    return {
        aboutMe: state.user.aboutMe,
        location: props.location,
    }
}

const mapDispatchToProps = dispatch => ({});

function Gainsight(props) {
    var platformUserSyncEnabled = isPlatformUserSyncEnabled();
    const { aboutMe, location } = props;
    const [initiated, setInitiated] = React.useState(false);
    const identify = () => {
        if (!window.aptrinsic || !aboutMe.user || !aboutMe.currentTenant || aboutMe.user.userType !== 'PROVIDER') {
            return;
        }

        // it is recommended to include the user and account along with custom attributes
        window.aptrinsic('identify',
            {
                //User Fields
                'id': ('PowerEngage|' + (aboutMe.currentTenant.salesforceId || '') + '|' + (platformUserSyncEnabled ? aboutMe.principal.guid:aboutMe.user.id || '')).toLowerCase(),
                'email': aboutMe.user.email, // Allowing all emails
                'signedUpAt': moment.utc(aboutMe.user.createdAt).unix(),
                'firstName': aboutMe.user.firstName,
                //'lastName': aboutMe.user.lastName,
                // Custom Attributes
                'engageSignUp': moment.utc(aboutMe.user.createdAt).unix(),
                'engageUsername': aboutMe.user.email,
                'engageUserType': Array.isArray(aboutMe.user.role) ? _.map(aboutMe.user.role, 'name').join(', ') : '',
                'sfdcId': aboutMe.currentTenant.salesforceId,
            },
            {
                //Account Fields
                'id': 'PowerEngage|' + (aboutMe.currentTenant.salesforceId || ''),
                'name': aboutMe.currentTenant.name,
                'sfdcId': aboutMe.currentTenant.salesforceId,
                // Custom Attributes
                //'Program': 'Platinum'
            });
    }

    React.useEffect(() => {
        //identify("update");
    }, [location]);

    if (window.aptrinsic && !initiated && aboutMe.user && aboutMe.currentTenant) {
        identify();
        setInitiated(true);
    }

    return (<div></div>);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gainsight));